<div class="you-are-in-safe-hands">
  <div class="grid-row">
    <!-- Banner -->
    <div class="xs-12 md-6 lg-6 ml-none">
      <img
        class="banner"
        alt="You are in safe hands"
        src="./assets/images/img-son-and-father-with-oven.jpg"
      />
    </div>
    <div class="xs-12 md-6 lg-6 ml-none mr-none pb-4 stats-container">
      <div class="m-12">
        <h3 class="protect-things">
          Count on us to look after the things you need
        </h3>
        <dl class="grid-row">
          <div class="xs-12 md-5 lg-5 stats">
            <dt class="stats-label">Customers in the UK</dt>
            <dd class="stats-amount mt-4">7.4 million</dd>
          </div>

          <div class="md-2 lg-2"></div>

          <div class="xs-12 md-5 lg-5 stats">
            <dt class="stats-label">Appliances repaired worldwide</dt>
            <dd class="stats-amount mt-4">2.5 million</dd>
          </div>

          <div class="xs-12 md-5 lg-5 stats">
            <dt class="stats-label">Appliances replaced in the UK</dt>
            <dd class="stats-amount mt-4">300,000</dd>
          </div>

          <div class="md-2 lg-2"></div>

          <div class="xs-12 md-5 lg-5 stats">
            <dt class="stats-label">Appliances replaced last year</dt>
            <dd class="stats-amount mt-4">490,000</dd>
          </div>
        </dl>
      </div>
      <div class="xs-12 md-12 lg-12 ml-none mr-none">
        <div class="ml-12 mr-12 note">
          <span>(All figures accurate, as of March 2024.)</span>
        </div>
      </div>
    </div>
  </div>
</div>
